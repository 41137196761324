<template>
  <div class="pageContol UnitEdit">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">客户中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">企业单位审核</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">企业单位 - {{unitJson.stu != 'view'? '审核':'详情'}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="所在城市" prop="areaId">
                  <el-cascader
                      clearable
                      filterable
                      v-model="ruleForm.areaId"
                      :options="areatreeList"
                      :props="propsarea"
                      size="small"
                      disabled
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="公司名称" prop="compName">
                  <el-input v-model="ruleForm.compName" size="small" maxlength="50" show-word-limit disabled></el-input>
                </el-form-item>
                <el-form-item label="社会信用代码" prop="creditCode">
                  <el-input v-model="ruleForm.creditCode" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="公司类型" prop="hrCompType">
                  <el-select v-model="ruleForm.hrCompType" placeholder="请选择" size="small" disabled>
                    <el-option
                        v-for="item in hrCompTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="公司规模" prop="hrCompScale">
                  <el-select v-model="ruleForm.hrCompScale" placeholder="请选择" size="small" disabled>
                    <el-option
                        v-for="item in hrCompScaleList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所属行业" prop="Industry">
                  <el-select
                      v-model="ruleForm.Industry"
                      clearable
                      filterable
                      placeholder="请选择"
                      size="small"
                      disabled
                  >
                    <el-option
                        size="small"
                        v-for="item in IndustryList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="公司地址" prop="compAddress">
                  <el-input v-model="ruleForm.compAddress" size="small" maxlength="50" show-word-limit disabled></el-input>
                </el-form-item>
                <el-form-item label="公司邮箱" prop="compEmail">
                  <el-input v-model="ruleForm.compEmail" size="small" maxlength="50" show-word-limit disabled></el-input>
                </el-form-item>
                <el-form-item label="公司简介" prop="hrAbout">
                  <el-input v-model="ruleForm.hrAbout" size="small" type="textarea" maxlength="1000" :autosize="{ minRows: 4, maxRows: 8}" show-word-limit disabled></el-input>
                </el-form-item>
                <el-form-item label="营业执照" prop="thumbnail">
                  <div v-if="ruleForm.compLicense">
                    <div style="display: flex; flex-direction: column">
                      <span style="color: #409eff;" >
                        <el-image
                            :src="
                                ruleForm.compLicense
                              "
                            fit="contain"

                            style="width: 238px;height: 169px;"
                        ></el-image>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <p>未上传营业执照</p>
                  </div>
                </el-form-item>

                <el-divider  v-if="unitJson.stu != 'view'"></el-divider>
                <el-form-item label="驳回理由" prop="authReason"  v-if="unitJson.stu != 'view'">
                  <el-input v-model="ruleForm.authReason" size="small" type="textarea" maxlength="200" :autosize="{ minRows: 4, maxRows: 8}" show-word-limit></el-input>

                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc" style="padding-bottom: 10px;" v-if="unitJson.stu != 'view'">
              <el-button @click="auditCompany('40')">不通过</el-button>
              <el-button class="bgc-bv" @click="auditCompany('30')">通 过</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- 预览附件 - loading -->
<!--    <el-dialog-->
<!--      title="预览"-->
<!--      :visible.sync="previewLoding1"-->
<!--      @close="uploadLodClose"-->
<!--      width="50%"-->
<!--      top="2%"-->
<!--      center-->
<!--    >-->
<!--      <div class="ovy-a">-->
<!--        <div-->
<!--          id="pdf-cert1"-->
<!--          style="height: 600px"-->
<!--          v-if="fileType == 'pdf' || fileType == 'PDF'"-->
<!--        ></div>-->
<!--        <div v-else>-->
<!--          <img :src="ImgSrc" alt="" width="100%" height="100%" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "UnitEdit",
  components: {},
  mixins: [UploadPic],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入联系人电话"));
      } else {
        callback();
      }
    };
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    var validateEMail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的邮箱地址"));
        } else {
          callback();
        }
      }
    };
    return {
      // previewLoding1:false,
      fileType:'',
      ImgSrc:'',
      stu: "add",
      // 基本信息
      ruleForm: {
        UnitName: "",
        company: "",
        creditCode: "",
        areaId: "",
        Industry: "",
        contactphone: "",
        compLegal: "",
        compIdcard: "",
        compEmail: "",
        compBusiness: "",
        compAddress: "",
        marketLeader:'', //市场负责人
        thumbnail: "",
        fileKey: "",
        parentCompId:'',
        marketId: '',    //销售人员
        compUser: '', // 企业联系人
        fileName:'',

        hrCompType:'',
        hrCompScale:'',
      },
      hrCompTypeList: [],
      hrCompScaleList: [],
      rules: {

      },
      IndustryList: [], // 行业
      companyList: [],
      unitJson: {},
      areatreeList: [],
      compSourceList: [],
      marketLeaderArr: [], //市场负责人下拉框数组对象
      options:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      marketArray: [], //销售人员可选列表
    };
  },
  created() {
    this.unitJson = this.$route.query;
    this.getmarketList();
    this.getIndustryList();
    this.getHrCode();
    if(this.unitJson.stu == "addsubordinate") {
      this.ruleForm.parentCompId = this.unitJson.compId
      // this.getCompany(this.ruleForm.parentCompId)

    }
    console.log(this.marketArray);
    // if (this.unitJson.stu == "edit") {
    this.queryData(this.unitJson.compId);

    // }
  },
  mounted() {
    this.getareatree();
  },
  computed: {},
  methods: {
    getHrCode() {
      const hrCompTypeList = this.$setDictionary("HR_COMP_TYPE", "list");
      const hrCompScaleList = this.$setDictionary("HR_COMP_SCALE", "list");
      let hrCompTypelist = [];
      for (const key in hrCompTypeList) {
        hrCompTypelist.push({
          value: key,
          label: hrCompTypeList[key]
        });
      }
      this.hrCompTypeList = hrCompTypelist;

      let hrCompScalelist = [];
      for (const key in hrCompScaleList) {
        hrCompScalelist.push({
          value: key,
          label: hrCompScaleList[key]
        });
      }
      this.hrCompScaleList = hrCompScalelist;
    },
    //销售人员列表
    getmarketList() {
      this.$post("/biz/user/sale/selectList", {
        saleType: "10",
      }).then((ret) => {
        if (ret.status == "0") {
          console.log(ret.data);
          this.marketArray = ret.data;
        }
      });
    },
    // 市场负责人列表
    getmarketManagerList() {
      this.$post("/sys/admin/market/manager").then(res => {
        if (res.status == "0") {
          this.options = res.data || [];
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        setTimeout(() => {
          this.loading = false;
          this.options = this.marketLeaderArr.filter(item => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = this.marketLeaderArr;
      }
    },
    //
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getIndustryList() {
      const IndustryList = this.$setDictionary("INDUSTRY", "list");
      const companyList = this.$setDictionary("COMPANYTYPE", "list");
      let list = [];
      let companylist = [];
      for (const key in IndustryList) {
        list.push({
          value: key,
          label: IndustryList[key]
        });
      }
      for (const key in companyList) {
        companylist.push({
          value: key,
          label: companyList[key]
        });
      }
      this.IndustryList = list;
      this.companyList = companylist;
    },
    auditCompany(authState){
      if(authState == '40'&&!this.ruleForm.authReason){
        this.$message({
          message: "请填写驳回理由！",
          type: "error"
        });
        return
      }else{
        let params = {
          authState: authState,
          compId: this.ruleForm.compId
        }
        if(authState == '40'){
          params.authReason = this.ruleForm.authReason
        }
        this.$post("/hr/company/audit/audit", params)
            .then(res => {
              if (res.status == 0) {
                this.$message({
                  message: "审核完成！",
                  type: "success"
                });
                this.$router.push({
                  path: "/web/UnitListAudit",
                  query: {
                    refresh: true
                  }
                });
              }
            })
            .catch(err => {
              return;
            });
      }
    },
    // // 编辑保存
    doAddSave() {
      const params = {
        compName: this.ruleForm.UnitName,
        compType: this.ruleForm.company,
        compNo: this.ruleForm.creditCode,
        areaId: this.ruleForm.areaId,
        compIndustry: this.ruleForm.Industry,
        compPhone: this.ruleForm.contactphone,
      };

      if (this.ruleForm.compUser) {
        params.compUser = this.ruleForm.compUser;
      }
      if (this.ruleForm.compLegal) {
        params.compLegal = this.ruleForm.compLegal;
      }
      if (this.ruleForm.parentCompId) {
        params.parentCompId = this.ruleForm.parentCompId;
      }
      if (this.ruleForm.compIdcard) {
        params.compIdcard = this.ruleForm.compIdcard;
      }
      if (this.ruleForm.compEmail) {
        params.compEmail = this.ruleForm.compEmail;
      }
      if (this.ruleForm.compBusiness) {
        params.compBusiness = this.ruleForm.compBusiness;
      }
      if (this.ruleForm.compAddress) {
        params.compAddress = this.ruleForm.compAddress;
      }
      if (this.ruleForm.fileKey) {
        params.compLicense = this.ruleForm.fileKey;
      }
      if (this.ruleForm.fileName) {
        params.compLicenseName = this.ruleForm.fileName;
      }
      if (this.ruleForm.marketLeader) {
        params.adminId = this.ruleForm.marketLeader;
      }
      if (this.unitJson.stu == "edit") {
        if (this.unitJson.compId) {
          params.compId = this.unitJson.compId;
        }
      }
      if (this.ruleForm.marketId) {
        params.marketId = this.ruleForm.marketId;
      }
      const el = this.$refs["ruleForm"];
      if (this.ruleForm.compIdcard == "") {
        el.clearValidate("compIdcard");
      }
      el.validate(valid => {
        if (valid) {
          this.$post(
            this.unitJson.stu == "add" ||  this.unitJson.stu == "addsubordinate"
              ? "/sys/company/insert"
              : "/sys/company/modify",
            params
          )
            .then(res => {
              ;
              if (res.status == 0) {
                this.$message({
                  message: this.unitJson.stu == "add" ? "新增成功" : "修改成功",
                  type: "success"
                });
                // setTimeout(() => {
                this.doCancel();
                // }, 3000);
              }
            })
            .catch(err => {
              return;
            });
        }
      });
    },
    // 上级单位
    getCompSourceList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.compSourceList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.compSourceList = [];
      }
    },
     /**
     * 上级单位回显
     */
    // getCompany(id) {
    //   this.$post("/sys/company/id", { compId: id }).then(res => {
    //     // ;
    //     this.compSourceList = [res.data];
    //   });
    // },
    // 编辑查询
    queryData(compId) {
      this.$post("/sys/company/info", { compId })
        .then(res => {
          const data = res.data || {};
          this.ruleForm = {
            ...data,
            company: data.compType,
            creditCode: data.compNo,
            areaId: data.areaId,
            Industry: data.compIndustry,
            contactphone: data.compPhone,
            compLegal: data.compLegal,
            compIdcard: data.compIdcard,
            compEmail: data.compEmail,
            compBusiness: data.compBusiness,
            compAddress: data.compAddress,
            thumbnail: data.compLicense,
            fileKey: data.compLicenseKey,
            parentCompId:data.parentCompId,
            compId:data.compId,
            marketLeader:data.adminId || '',
            marketId: data.marketId,
            compUser: data.compUser,
            fileName:data.compLicenseName || '',
            compLicenseFileType:data.compLicenseFileType || '',
            authReason:''
          };

           // this.getCompany(data.parentCompId ? data.parentCompId : data.compId);
        })
        .catch(err => {
          return;
        });
    },
  }
};
</script>
<style lang="less">
.UnitEdit {
  .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      margin-bottom: 22px;
    }
  }
   .el-upload {
  height: 180px !important;
}
.upload-btn {
  height: 180px;
}
}

</style>
